.button-container {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: #f7f3f2;

  button.btn,
  button.btn:active {
    border-radius: 0 !important;
    background-color: #f7f3f2;
    border: none;
    color: #191b23;
    font-weight: 700;
    line-height: 32px;
    font-size: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;

    .div img {
      position: relative;
      bottom: 2px;
    }
  }
}

.sticky-header-wrapper {
  position: fixed;
  top: 60px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1); /* Smoother and slightly slower */
  padding: 16px;
  padding-top: 24px;
  font-size: 14px;
  transform: translateY(0);
  opacity: 1;
}

.sticky-header-wrapper.header {
  width: 100%;
  transform-origin: top;

  .sticky {
    transform: translateY(0);
  }

  &:not(.sticky) {
    transform: translateY(-200%);
    opacity: 0;
  }
}

.main-content {
  flex: 1;
  overflow-y: auto;
  min-width: 300px;
  max-width: 1080px;
  padding-left: 32px;
  padding-right: 64px;
}

.metadata-container h2 {
  margin-bottom: 20px;
}

.metadata-container h3 {
  margin: 15px 0;
}

.metadata-container section {
  margin-bottom: 30px;
}

.info-row {
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
  padding-bottom: 12px;
  gap: 10px;
}

.field {
  display: flex;
  align-items: center;
  gap: 16px;
  * {
    display: inline-block;
    font-family: "Roboto", sans-serif;
  }
}

.label {
  font-weight: 500;
  line-height: 20px;
  color: #424654;
  font-size: 14px;
  letter-spacing: 0.1px;
  text-transform: none !important;
}

.field-content {
  font-weight: 400;
  line-height: 24px;
  color: #1c1b1b;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.short-label {
  width: 320px;

  .label {
    width: 88px;
  }

  .field-content {
    width: fit-content;
  }
}

.long-label {
  width: 320px;

  .label,
  .field-content {
    width: 50%;
  }
}

.long-field-content {
  width: fit-content;
  min-width: 360px;

  .label {
    width: 88px;
  }

  .field-content {
    width: fit-content;
  }
}

.issue-section-container {
  padding-bottom: 240px;
}

.issue-content-row {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  gap: 32px;
}

.issue-field-label {
  width: 120px;
}

.issue-field-content {
  width: fit-content;
}

.circle {
  margin-right: 8px;
}

.concerning-level-maintenance {
  background-color: #7a94ab !important;
}

.concern-level-badge {
  align-self: center;
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 500 !important;
}

.concerning-level-recommendation {
  background-color: #ffb5a0 !important;
}

.concerning-level-safety-hazard {
  background-color: #d83a00 !important;
}

/* .issue-images-grid {
  max-width: 412px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 8px;
  width: 100%;
}

.image-container {
  aspect-ratio: 1/1;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.issue-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
} */

.issue-images-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.image-container {
  width: calc((100% - 16px) / 3);
  max-width: 412px;
  aspect-ratio: 1/1;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.issue-image {
  width: 100%;
  height: 100%;
  object-fit: contain;

  &:hover {
    cursor: pointer;
  }
}

.total-issues {
  font-size: 16px;
  font-weight: 400;
  color: #191b23;
}

@media (min-width: 992px) {
  /* Hide the button container on medium and larger screens */
  .sticky-header-wrapper {
    top: 0px;
    margin-left: 360px;

    .header:not(.sticky) {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  .web-report-container {
    display: flex;
  }

  .button-container {
    display: none;
  }

  .main-content {
    margin-left: 360px;
    flex: 1;
  }
}

/* Adjust font size for smaller screens */
@media screen and (max-width: 1200px) {
  .main-content {
    padding-left: 24px;
    padding-right: 24px;
  }
}

/* Adjust font size for smaller screens */
@media screen and (max-width: 600px) {
  .main-content {
    padding-left: 16px;
    padding-right: 16px;
  }

  .concern-level-badge {
    font-size: 12px;
  }

  .total-issues {
    font-size: 12px;
  }

  .issue-content-wrapper {
    .issue-content-row {
      flex-direction: column;
      gap: 8px;
    }
  }
}

/* Adjust font size for smaller screens */
@media screen and (min-width: 768px) and (max-width: 992px) {
  .image-container {
    width: calc((100% - 16px) / 2);
  }
}

@media screen and (max-width: 768px) {
  .image-container {
    width: 100%;
  }
}

@media print {
  .sticky-header-wrapper {
    display: none;
  }

  .drawer {
    display: none !important;
  }

  .main-content {
    margin-left: 12px;
    margin-right: 12px;
    max-width: 100%;
  }

  /* Basic print settings */
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  /* Image grid layout */
  .image-container,
  .photo-container {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
    gap: 16px !important;
    margin-bottom: 24px !important;
    page-break-inside: avoid !important;
  }

  /* Individual images */
  img {
    width: 100% !important;
    height: auto !important;
    object-fit: contain !important;
  }

  .issue-section-container {
    padding-bottom: 24px !important;
  }

  .subtitle {
    font-size: 24px;

    .subtitle-text {
      flex: 1 1 auto;
      max-width: 70%;
    }

    .subtitle-circle-container {
      flex: 0 0 auto;
    }
  }
}
