.project-info-form .form-label {
  padding-left: 2px;
  margin-bottom: 0;
  font-weight: bold;
}

.project-info-form .form-control {
  margin-bottom: 1rem;
}

.required-field,
.error {
  color: red;
}

.project-info-form input:read-only,
textarea:read-only {
  background-color: #f1f1f1 !important;
}
