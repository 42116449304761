.issue-form .form-label {
  padding-left: 2px;
  margin-bottom: 0;
  font-weight: bold;
}

.issue-form .form-control {
  margin-bottom: 1rem;
}

.sub-label {
  color: #606060;
  font-weight: 500 !important;
}
