.drawer {
  transform: none !important;
  padding: 8px;
  height: 100vh;
  visibility: visible !important;
  background-color: #f7f3f2;
  display: flex;
  flex-direction: column;
}

.drawer-content-container {
  overflow-y: auto;
}

.offcanvas-header {
  padding: 24px 8px !important;
}

.offcanvas-body {
  padding: 0 !important;
}

.title-wrapper {
  padding-top: 32px;
  padding-bottom: 16px;
}

.top-title-truncate {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.title {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.subtitle {
  font-weight: 700;
  font-size: 28px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item {
  font-weight: 700;
  font-size: 24px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 0px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.nav-link {
  padding-left: 16px; /* Adjust the indention with the border-bottom*/
  padding-top: 16px;
  padding-bottom: 16px;
  color: #1c1b1b;
  font-weight: 500;

  &:hover {
    color: #1c1b1b;
  }
}

.section-accordion-container *:not(.circle) {
  background-color: #f7f3f2 !important;
  border: none;
  border-radius: 0 !important;
}

.accordion-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;

  &:last-child {
    border-bottom: none !important;
  }
}

.accordion-header {
  .accordion-button {
    padding-left: 8px !important; /* Adjust the indention with the border-bottom*/
    &:not(.collpased) {
      box-shadow: none !important;
    }

    color: #1c1b1b !important;
    font-weight: 500;
    padding: 20px;
    padding-left: 0px;
    padding-right: 20px;
  }
}

.accordion-body {
  padding: 0 !important;

  .nav .nav-link {
    padding: 12px 0px;
  }
}

.subsection-accordion-container {
  .accordion-item {
    border-bottom: none !important;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 0px;

    .according-header .accordion-button {
      padding-top: 8px !important;
    }

    .accordion-body {
      padding: 8px !important;
    }
  }
}

/* Custom styles for the accordion toggle icon */
.accordion-button::after {
  /* Change the icon size */
  transform: scale(0.8);
}

/* Rotate the icon when the accordion is open */
.accordion-button:not(.collapsed)::after {
  transform: rotate(180deg) scale(0.8); /* Rotate and scale */
}

/* Target the specific nav item structure */
.metadata-nav-item.nav div.nav-item-active {
  background-color: rgba(25, 118, 210, 0.08) !important;
  border-radius: 10px !important;
}

.metadata-nav-item.nav div.nav-item-active .d-flex {
  background-color: transparent !important;
}

/* Make sure transitions are synchronized */

.subsection-accordion-container {
  .nav-link,
  .nav-link div {
    transition: background-color 0.2s ease;
  }

  .issue-nav-item-active {
    background-color: rgba(25, 118, 210, 0.08) !important;
    border-radius: 10px !important;

    div:not(.circle) {
      background-color: transparent !important;
    }
  }

  .nav-link:not(.issue-nav-item-active) {
    background-color: transparent !important;

    div:not(.circle) {
      background-color: transparent !important;
    }
  }
}

/* Make drawer responsive */
@media (min-width: 992px) {
  .drawer {
    width: 360px;
    position: fixed;
  }

  .drawer-content-container {
    height: calc(100vh - 144px);
    padding-bottom: 24px;
  }
}

/* Adjust font size for smaller screens */
@media screen and (max-width: 1200px) {
  .title {
    font-size: 32px;
  }

  .subtitle {
    font-size: 24px;

    .subtitle-text {
      flex: 1 1 auto;
      max-width: 70%;
    }

    .subtitle-circle-container {
      flex: 0 0 auto;
    }
  }

  .item {
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media screen and (max-width: 600px) {
  .title-wrapper {
    padding-top: 24px;
    padding-bottom: 12px;
  }

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
    padding-bottom: 8px !important;

    .circle {
      margin-right: 4px;
    }
  }

  .item {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

/* OR using the scroll-margin approach */
@media screen and (max-width: 992px) {
  .metadata-container {
    scroll-margin-top: 60px;
  }

  .issue-container {
    scroll-margin-top: 60px;
  }
}
