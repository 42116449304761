/* Base font settings */

body {
  font-family:
    "Roboto",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  font-size: 16px; /* Base font size */
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Typography scale */
h1 {
  font-size: 2rem; /* 32px */
  font-weight: 500;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem; /* 24px */
  font-weight: 500;
  margin-bottom: 0.75rem;
}

h3 {
  font-size: 1.25rem; /* 20px */
  font-weight: 500;
  margin-bottom: 0.5rem;
}

h4 {
  font-size: 1.125rem; /* 18px */
  font-weight: 500;
  margin-bottom: 0.5rem;
}

/* Body text variations */
.text-small {
  font-size: 0.875rem; /* 14px */
}

.text-large {
  font-size: 1.125rem; /* 18px */
}

/* For navigation items in drawer */
.nav-item {
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
}

/* For labels and captions */
.label {
  font-size: 0.75rem; /* 12px */
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
