/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap"); */

.project-info-wrapper {
  width: 80%;
  margin: 0 auto;
}

.default-header {
  background-color: #e5e5e5;
  border-radius: 0 0 20px 20px;
  padding: 20px;
}

.header-title {
  color: #000;
  text-align: center;

  /* Main Heading */
  font-family: Work Sans;
  /* font-size: 70px; */
  font-style: normal;
  font-weight: 700;
  line-height: 80%; /* 70px */
  letter-spacing: -1.4px;
  text-transform: uppercase;
}
